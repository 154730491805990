.quotationdetails {
  background: #fafafa;
}

.quotationdetails-mr {
  margin-left: 10px;
  margin-right: 10px;
}

.quotationdetails-box {
  padding: 15px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 10px;
}

.quotationdetails__box {
  padding: 15px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 10px;
}

.quotationdetails-item__flex-pard {
  background: #ffffff;
  border-radius: 5px;
}

.quotationdetails__card {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 10px;
}

.quotationdetails-reference {
  font-weight: 700;
  font-size: 15px;
  color: #1d1d1b;
}

.quotationdetails-head-ti__ {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.quotationdetails-head-ti {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.quotationdetails-view-more {
  width: 102px;
  height: 28px;
  padding: 2px 5px 2px 5px;
  background: #f7f7f7;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
  cursor: pointer;
}

.quotationdetails-btn {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 14px;
  margin-right: 14px;
}

.quotationdetails-head-title {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #008fc3;
}

.quotationdetails-head-title__ {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.chevron_text_title {
  font-weight: 500;
  font-size: 14px;
  /* text-transform: uppercase; */
}

.quotationdetails-head-title_ {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #008fc3;
}

.quotationdetails__btn {
  margin: 1rem 1.5rem;
}

.quotationdetails-issues-box {
  padding: 10px 15px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 10px;
}

.quotationdetails-issues-box__ {
  padding: 10px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 10px;
}

.quotationdetails-description {
  font-weight: 500;
  font-size: 15px;
  color: #1d1d1b;
  margin-top: 2px;
  margin-bottom: 3px;
}

.quotationdetails__description {
  font-weight: 400;
  font-size: 15px;
  color: #1d1d1b;
  margin-top: 2px;
  margin-bottom: 3px;
}

.issues-count {
  background: #f49600;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  margin-left: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #1d1d1b;
}

.contactUsLink {
  font-weight: 600;
  color: #E56606;
}

.issue_count {
  font-weight: 600;
  font-size: 10px;
  color: #1d1d1b;
  border-radius: 50%;
  background-color: #f49600;
  padding: 0.6em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.issue_count__value {
  overflow: visible;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.issue_count:after {
  content: "";
  display: block;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
}

.quotationdetails-message {
  font-weight: 400;
  font-size: 14px;
  color: #f49600;
}

.quotationdetails_message {
  font-weight: 500;
  font-size: 14px;
  color: #f49600;
}

.quotationdetails-message_ {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.quotationdetails-item__flex-pard-title {
  font-weight: 600;
  font-size: 15px;
  color: #a2b4b9;
}

.quotationdetails-item-flex {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quotationdetails-item-pd {
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
}

.quotationdetails-item-number {
  min-width: 25px;
  height: 25px;
  background: #ececec;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
  font-size: 12px;
  color: #8e8f8f;
}

.quotationdetails-item__flex-col {
  width: 250px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.quotationdetails-item-productCode {
  font-weight: 500;
  font-size: 13px;
  color: #1d1d1b;
}

.quotationdetails-item__price {
  font-weight: 500;
  font-size: 15px;
  color: #1d1d1b;
  white-space: nowrap;
  text-align: right;
}

.quotationdetails-item-description {
  font-weight: 400;
  font-size: 14px;
  color: #1d1d1b;
  margin-bottom: 10px;
}

.quotationdetails-item-flex-row__popup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quotationdetails-item-flex-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 3.5rem;
  max-width: 300px;
  margin-bottom: 10px;
}

.quotationdetails-item-flex-row_ {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 3.5rem;
  max-width: 220px;
  margin-bottom: 10px;
}

.issues_bg {
  user-select: none;
  background-color: #e1f7fc;
  padding: 7px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chevron_aerrow_bg {
  user-select: none;
  padding: 7px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quotationdetails-item-gray-text {
  font-weight: 400;
  font-size: 12px;
  color: #7e7e7e;
}

.quotationdetails-item-price {
  font-weight: 500;
  font-size: 13px;
  color: #1d1d1b;
}

@media only screen and (max-width: 415px) {
  .quotationdetails-item-price {
    font-size: 3.4vw;
  }
}

.quotationdetails-item__flex-row {
  margin-left: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quotationdetails-item-quantity {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-left: 6px;
}

.quotationdetails-item-quantity___ {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-left: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.quotationdetails-item-quantity_____ {
  font-weight: 600;
  font-size: 14px;
  color: #E56606;
  margin-left: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.quotationdetails-item-discontinued {
  width: 92px;
  height: 27px;
  background: #ffecec;
  border-radius: 60px;
  font-weight: 500;
  font-size: 13px;
  color: #ff7070;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quotationdetails-item-find-alter {
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  color: #f49600;
  margin-right: 1rem;
}

.quotationdetails-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.quotationdetails-item-delivery-p {
  padding: 15px 20px;
}

.quotationdetails-item-delivery-p-ship {
  padding: 0px 20px 25px 20px;
}

.quotationdetails-item-delivery-flex {
  display: flex;
  justify-content: space-between;
}

.quotationdetails-item-additem {
  padding: 1px 10px;
  height: 27px;
  background: #008fc3;
  border-radius: 60px;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  right: calc(50% - 50px);
  top: -12px;
}

.quotationdetails-item-additem>span {
  margin-left: 5px;
}

.quotationdetails-item-additems {
  padding: 1px 10px;
  height: 27px;
  background: #008fc3;
  border-radius: 60px;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90px;
}

.quotationdetails-item-additems>span {
  margin-left: 5px;
  text-decoration: none;
}

.addAndQuickBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: absolute;
  bottom: -14px;
  right: 0;
  left: 0;
}

.quotationdetails-item-total-pd {
  padding: 15px 20px;
}

.quotationdetails-item-total-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.quotationdetails-item-total__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quotationdetails-item-total-text {
  font-weight: 400;
  font-size: 16px;
  color: #1d1d1b;
}

.quotationdetails-item-grosstotal {
  font-weight: 600;
  font-size: 16px;
  color: #1d1d1b;
}

.ticket-border {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ticket-shape {
  height: 20px;
  width: 50px;
  background-image: radial-gradient(circle at 13px 20px,
      rgba(214, 213, 213, 0.055) 0.7em,
      white 0.8em);
  margin-bottom: 1rem;
}

.holes-top {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: -9px;
  left: -10px;
  background-color: #fafafa;
  box-shadow: inset -2px 0px 0px rgba(214, 213, 213, 0.25);
}

.holes__top {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: -9px;
  right: -10px;
  background-color: #fafafa;
  box-shadow: inset 2px 0px 0px rgba(214, 213, 213, 0.25);
}

.quotationdetails-item__flex-p {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

/* ============================= ADD ITEM ========================= */

.add-item {
  background-color: #fbfeff;
}

.add-item-flex-p {
  padding: 12px 35px 12px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-item-clear-filters {
  font-weight: 400;
  font-size: 15px;
  text-decoration-line: underline;
  color: #869a9f;
}

.add-item-quick-add-icon {
  font-weight: 500;
  font-size: 15px;
  color: #1d1d1b;
}

.add-item-flex-col {
  display: flex;
  flex-direction: column;
}

.add-item-flex-mp {
  padding: 0px 35px 10px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-item-btn {
  margin-left: 23px;
  margin-bottom: 20px;
  margin-right: 23px;
}

.add-item-quick-add {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}

.add-item-quick-add-add {
  width: 104px;
  height: 28px;
  background: #f9f9f9;
  border: 1px solid #1d1d1b;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.add-item-input-sec {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

/* ============================= CONFIRM ORDER ========================= */

.confirm-order {
  background-color: #fbfeff;
  margin: auto;
}

.confirm-order-box {
  padding: 15px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 10px;
}

.confirm-order__box {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 2rem;
}

.confirm-order-card {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.confirm-order-stock-loc {
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
}

.confirm-order-btn {
  margin: 2rem 1.6rem;
}

/* =========================================================== */

.confirm-shipment {
  background-color: #fbfeff;
  margin: auto;
}

.confirm-shipment-img {
  position: relative;
}

.confirm-shipment-img>img {
  width: 95%;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  background: linear-gradient(91.93deg, #d9fad1 0.41%, #e1f7fc 99.7%);
  border-radius: 5px;
}

.confirm-shipment-img-success {
  position: absolute;
  top: 20%;
  left: calc(50% - 5px);
}

.confirm-shipment-img-text {
  width: 300px;
  position: absolute;
  top: 50%;
  left: calc(50% - 150px);
  text-align: center;
}

.confirm-shipment-img-order {
  font-weight: 400;
  font-size: 14px;
  color: #349f46;
}

.confirm-shipment-img-info {
  font-weight: 500;
  font-size: 14px;
  color: #349f46;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.confirm-shipment-stock-info {
  height: 65px;
  background: #fff4e2;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding-left: 4%;
  padding-right: 4%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}

.confirm-shipment-stock-info>span {
  font-weight: 600;
  font-size: 16px;
  color: #f49600;
}

.confirm-shipment-box {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.confirm-shipment__box {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.confirm-shipment-Ref-id {
  font-weight: 500;
  font-size: 15px;
  color: #a2b4b9;
  margin-top: 5px;
  margin-bottom: 10px;
}

.confirm-shipment-dates {
  font-weight: 500;
  font-size: 15px;
  color: #1d1d1b;
  margin-left: 10px;
}

.confirm-shipment-view-more {
  width: 102px;
  height: 28px;
  padding: 2px 5px 2px 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
}

.confirm-shipment-view-more_ {
  width: 102px;
  height: 28px;
  padding: 2px 5px 2px 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
}

.confirm-shipment__view-more {
  width: 25px;
  height: 25px;
  background: #f7f7f7;
  border-radius: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.confirm-shipment-udnad {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 3rem;
  margin-right: 1rem;
}

.current-quotes-card-instock__ {
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  padding: 3px 15px;
  margin-left: 10px;
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.confirm-shipment-expected {
  max-width: 130px;
  padding: 1px 10px;
  height: 27px;
  background-color: #eaf6fa;
  border-radius: 60px;
  font-weight: 400;
  font-size: 12px;
  color: #80a6b1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-shipment-expected>span {
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.confirm-shipment-exp {
  font-weight: 400;
  font-size: 14px;
  color: #88989d;
  margin-right: 2px;
}

.confirm-shipment-btn {
  margin-left: 27px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-right: 27px;
}

.confirm-shipment-textarea {
  margin: 10px;
}

.confirm-order-lookup {
  width: 100px;
  height: 52px;
  font-weight: 600;
  font-size: 14px;
  background-color: #e2f1fd;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #008fc3;
  margin: 0px 0px 0px 5px;
  user-select: none;
  border: 0;
}

.confirm-order-lookup-m {
  margin-bottom: 0.6rem;
}

/* =============================================================================== */

.shipment-details {
  background-color: #fbfeff;
  margin: auto;
}

.shipment-details-btn {
  margin-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
}

.shipment-details-btn_ {
  background-color: #ffffff;
  width: 360px;
  position: fixed;
  bottom: 0px;
  padding: 1rem 1.5rem;
}

.shipment-details-num {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* ================================================================================== */

.card-payment {
  background-color: #fbfeff;
  margin: auto;
}

.card-payment-box {
  margin-left: 10px;
  margin-right: 10px;
  width: 80px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.card-payment-btn {
  margin-left: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 1.5rem;
}

.card-payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 1.5rem;
}

.card-payment-info {
  width: 360px;
  margin: auto;
  padding: 11px;
  background: #f2f7f9;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.card-payment-info-text {
  font-weight: 400;
  font-size: 13px;
  color: #8ea9b1;
  margin-left: 10px;
}

/* ============================= EDIT SHIPMENTS ========================= */

.edit-shipment {
  background-color: #fbfeff;
  margin: auto;
}

.edit-shipment-bg-mr {
  margin-left: 10px;
  margin-right: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
}

.edit-shipment-select-all {
  font-weight: 500;
  font-size: 12px;
  color: #88989d;
}

.edit-shipment-other-item-info {
  font-weight: 500;
  font-size: 13px;
  color: #e56606;
  margin: 0px 0px 20px 20px;
}

.edit-shipment-other-item-check {
  padding-left: 15px;
  display: flex;
  padding-bottom: 1rem;
}

.edit-shipment-other-item-udn {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* ============================= ONLINE PAYMENT ========================= */

.online-payment {
  background-color: #fbfeff;
  margin: auto;
}

.online-payment-upload {
  margin: 20px 15px 20px 15px;
  height: 159px;
  background: #ffffff;
  border: 1px dashed #d7e8ec;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.online-payment-upload>img {
  margin: 2rem 1rem 1rem 1rem;
}

.online-payment-text {
  font-weight: 600;
  font-size: 14px;
  color: #a2b4b9;
  margin: 5px;
}

.online-payment__text {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #a2b4b9;
}

/* ============================= QUICK ADD ========================= */

.quick-add {
  background-color: #fbfeff;
}

.quick-add-box {
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin-bottom: 0.6rem;
}

.quick-add-flex {
  display: flex;
}

.quick-add-input {
  width: 78px;
}

.quick-add-flex-col {
  display: flex;
  flex-direction: column;
  min-width: 90px;
}

.quick-add__flex-col {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  min-width: 80px;
}

.quick-add-flex-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.quick-add-stock {
  min-width: 70px;
  font-weight: 400;
  font-size: 13px;
  color: #a2b4b9;
}

.quick-add-body {
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
}

.quick-add-add-item {
  padding: 20px;
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: 15px;
  text-decoration-line: underline;
  color: #a2b4b9;
  cursor: pointer;
}

.quick-add-add-item-hide {
  padding: 20px;
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: 15px;
  text-decoration-line: underline;
  color: #a2b4b9;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}

.quick-add-btn {
  margin-left: 1.5rem;
  margin-bottom: 2rem;
  margin-right: 1.5rem;
}

/* ===================  EDIT QUOTE  =========================== */
.edit-quote-item-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-card-err-info__ {
  background-color: #ffecec;
  border-radius: 5px;
  padding: 10px 10px 10px 14px;
  color: #ff7070;
  /* margin-top: 15px; */
  margin-bottom: 10px;
  /* padding-left: 10px; */
}

.create-order-card-err-info__ {
  background-color: #ffecec;
  border-radius: 5px;
  padding: 10px 10px 10px 14px;
  color: #ff7070;
  /* margin-top: 15px; */
  margin-bottom: 10px;
  /* padding-left: 10px; */
  margin: 10px;
}

.order-card__info-text {
  font-weight: 400;
  font-size: 14px;
}


.qustions_confirm_div {
  background-color: #0dd82c;
  /* background-color: #EEFCF0; */
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px 0;
  display: flex;
  font-size: 12px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: underline;
}

.qustions_confirm_div:hover {
  cursor: pointer;
}

.qustions_confirm_div>span {
  margin: 0 0 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}