html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Jost', sans-serif;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
  background: gray;
  overscroll-behavior-y: none;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

* {
  font-family: 'Jost';
  font-style: normal;
  scroll-behavior: smooth;
}

img {
  user-select: none;
}

.container_ {
  margin: 0px 10px;
}

/* ========== Menu Heading Title ============= */

.menu-heading-title {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.menu-heading-title>span {
  font-weight: 500;
  font-size: 18px;
  margin-left: 0.5rem;
  color: #000000;
}

.menu-heading-title__>span {
  font-weight: 500;
  font-size: 18px;
  margin-left: 0.5rem;
  color: #008FC3;
}

.menu-heading_title {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.menu-heading_title>span {
  font-weight: 700;
  font-size: 18px;
  margin-left: 0.5rem;
  color: #000000;
}

.display_none {
  display: none !important;
}

/* ================= Text Input / Drop Down = Title ========== */

.text-input {
  display: flex;
  flex-direction: column;
}

.text-input__title {
  margin-bottom: 0.6rem;
  font-weight: 500;
  font-size: 14px;
}

.text-input___title {
  margin-bottom: 0.6rem;
  font-weight: 500;
  font-size: 14px;
}

/* ============ Button Blue Color ============= */

.button {
  width: 350px;
  height: 50px;
  background: linear-gradient(91.28deg, #00aee1 0%, #55d0ec 100%);
  box-shadow: 0px 0px 19px #ccf4fd;
  border-radius: 5px;
  border: none;
  color: #ffffff;
}

.button>span {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

/* ========== Margin =========== */

.margin_bottom {
  margin-bottom: 10px;
}

/* =============== BG IMG & Back Icon ===========  */

.bg__img {
  background-image: url('/public/images/HistoryBG.svg');
  background-size: 415px auto;
  background-repeat: no-repeat;
}

.bg-img__Back-icon {
  background-color: #f9f9f9;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.1rem;
  text-decoration: none;
}

.back_nav-bg {
  background-color: #fafafa;
}

.bg-img__Back-icon>img {
  width: 24px;
  height: 24px;
  margin-left: 0.5rem;
}

.bg-img__Back-icon>span {
  font-weight: 500;
  font-size: 16px;
  margin-left: 0.7rem;
  color: black;
}

.sub-menu-box {
  height: 100vh;
}

.sub-menu- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding-left: 1rem;
  text-decoration: none;
}

.sub-menu--contant {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.sub-menu--name {
  padding: 1rem;
  color: black;
}

.sub-menu--icon>img {
  padding-right: 1.5rem;
}

.sub-menu-box-title {
  margin-left: 1.5rem;
  margin-top: 0rem;
}

/* ============= Link Under line Remove ============= */

.link {
  text-decoration: none;
}

/* ============== Setting Button ================== */

.settings__btn-align {
  margin: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.setting_status_true_margin {
  margin: 10px 10px 10px 0px;
}

/* ====================== Drop Down ID ========================== */

#selection-text {
  min-width: 320px;
  max-width: 367px;
  color: #000;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  outline: 0;
  font-size: 14px;
}

#selection-text::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

/* =============== Country Icon =============== */

.country-icon {
  width: 40px;
  height: 25px;
  border-radius: 5px;
}

/* =========== Defulat Img ============== */

.default-img {
  width: 100%;
  aspect-ratio: 71/29;
  height: auto;
  object-fit: contain;
}

.icon_set_24 {
  height: 22px;
  width: 22px;
}

/* ================= Back Menu ============ */

.backmenu {
  display: flex;
  flex-direction: column;
  padding: 15px 8px;
}

/* ================= Square Box ============*/

.square-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 8px;
  margin-right: 8px;
}

.square__box {
  background-color: #ffffff;
  box-shadow: 0px 0px 21px rgba(218, 218, 218, 0.25);
  border-radius: 10px;
  height: 173px;
  width: 195px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.square-box_data {
  height: 90px;
  width: 90px;
  object-fit: contain;
}

.square-box_data__m {
  height: 110px;
  width: 110px;
  object-fit: contain;
}

.square-box-title {
  width: 120px;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-top: -1rem;
}

@media only screen and (max-width: 406px) {
  .square__box {
    height: 150px;
    width: 175px;
  }
}

@media only screen and (max-width: 390px) {
  .square__box {
    height: 173px;
    width: 180px;
  }
}

@media only screen and (max-width: 380px) {
  .square__box {
    height: 173px;
    width: 173px;
  }
}

@media only screen and (max-width: 370px) {
  .square__box {
    height: 173px;
    width: 165px;
  }
}

/* ====================== Stock Section ================= */

.stock_succ-box {
  margin-top: 4%;
  padding: 0px 10px 0px 10px;
  height: 30px;
  background: #e0f8da;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stock_succ-box_ {
  padding: 0px 10px 0px 10px;
  height: 30px;
  background: #e0f8da;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stock_succ-box_text {
  font-weight: 500;
  font-size: 12px;
  color: #57aa43;
  margin-left: 7px;
}

/* =================== quantity Number ( + - ) ================ */

.quantity-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 11px;
}

.quantity_title {
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.quantity_p-m {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.quantity_p-m>span {
  font-weight: 400;
  font-size: 15px;
  color: #1d1d1b;
}

.quantity_minus-btn {
  width: 25px;
  height: 24px;
  background: #ececec;
  border-radius: 3px;
  border: 0;
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.quantity_plus-btn {
  width: 25px;
  height: 24px;
  background: #00aee1;
  border-radius: 3px;
  border: 0;
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.quantity-number {
  width: 40px;
  text-align: center;
  border: none;
}

.quantity-number:focus {
  outline: none;
  border-bottom: 1px solid #d7e8ec;
}

.minus_plus_icon {
  height: 11px;
  width: 11px;
}

/* =============== NO Data Found ================ */

.no-data-found {
  height: 400px;
  font-weight: 600;
  font-size: 16px;
  color: #a2b4b9;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.no-data-found-ctr {
  height: 400px;
  font-weight: 600;
  font-size: 16px;
  color: #a2b4b9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.no-data-found-sm {
  height: 75px;
  font-weight: 600;
  font-size: 16px;
  color: #a2b4b9;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.no-data-found-sm-center {
  font-weight: 600;
  font-size: 16px;
  color: #a2b4b9;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  text-align: center;
}

/* =================== Filter Side Menu ================ */

.filter_height {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}

.filter_height::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* ======================= Back Text ================== */

.back__text {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #1d1d1b;
}

.back-text__title {
  width: clamp(250px, 100%, 285px);
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #1d1d1b;
}

/* ====================== Search Bar ================== */

.searchbar {
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.searchbar>img {
  margin-left: 1rem;
}

.searchbar-name {
  margin-left: 1rem;
  font-weight: 400;
  font-size: 15px;
  color: #979797;
}

.searchbar-input {
  border: 0;
  margin-left: 1rem;
  width: 100%;
  font-size: 15px;
  outline: none;
  background-color: #ffffff;
}

.searchbar-border {
  border: 0.5px solid #00aee1;
}

.search-h {
  margin-left: 13px;
  margin-right: 14px;
}

.search_h {
  margin-left: 10px;
  margin-right: 10px;
}

/* ================================ Filter Button & Modual ================= */
.filter-flex-m {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.filter-sortby-fn {
  width: 131px;
  height: 37.5px;
  border: 0.5px solid #62c6e9;
  background: #fafdfe;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  /* background-color: transparent; */
}

.filter-sortby-fn>span {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin-right: 1.2rem;
}

.filter-flex-padding {
  width: 300px;
}

.filter-flex-row-p {
  padding: 1rem 2rem 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.check_modual {
  margin-left: 1.5rem;
  font-size: 14px;
}

@media only screen and (max-width: 380px) {
  .check_modual {
    margin-left: 2.5rem;
  }

  .filter-btn-flex>span {
    margin-left: 40px;
  }
}

.filter-flex-row-p>span {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: #1d1d1b;
}

.filter-box {
  width: 300px;
  background: #ffffff;
}

.filter-btn-flex {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.filter-btn-flex>span {
  font-weight: 700;
  font-size: 14px;
  text-decoration-line: underline;
  color: #a2b4b9;
  cursor: pointer;
}

.filter-btn {
  width: 140px;
  height: 40px;
  background: linear-gradient(91.28deg, #00aee1 0%, #55d0ec 100%);
  box-shadow: 0px 0px 19px #ccf4fd;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.filter-scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  overflow-x: scroll;
  max-height: 10rem;
}

.filter-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.filter-flex-m-p {
  padding: 0.5rem;
  background: #ebf5f9;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}

.filter-flex-m-p>img {
  margin-left: 10px;
}

.filter-clear {
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  color: #a2b4b9;
  cursor: pointer;
}

.filter-data-no {
  font-weight: 400;
  font-size: 15px;
  color: #1d1d1b;
  margin-left: 2rem;
}

/* ================================= Button ======================== */

.gradient-button-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.gradient-button-primary {
  position: relative;
  width: 100%;
  height: 50px;
  background: linear-gradient(91.28deg, #00aee1 0%, #55d0ec 100%);
  box-shadow: 0px 0px 19px #ccf4fd;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 15px;
}

.gradient-button-primary:active {
  transform: scale(0.98);
}

.gradient-button-primary>span {
  font-family: 'Jost';
  font-style: normal;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 14px;
}

.gradient-button-white {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #000000;
  background-color: white;
  border-radius: 5px;
  color: black;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding: 15px;
}

.gradient-button-white>span {
  margin-left: auto;
  margin-right: auto;
  font-family: 'Jost';
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.gradient-button-white:active {
  transform: scale(0.98);
}

/* =========================== Small Button ==================== */

.small-btn-blue {
  width: 106px;
  height: 34px;
  margin-left: 0.5rem;
  background: linear-gradient(91.28deg, #00aee1 0%, #55d0ec 100%);
  box-shadow: 0px 0px 19px #ccf4fd;
  border-radius: 5px;
  border: 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.small-btn-white {
  width: 106px;
  height: 34px;
  border: 0;
  border-radius: 5px;
  margin-left: 0.5rem;
  background: #d6d6d6;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.product-img {
  height: 25px;
  width: 25px;
}

/* ========================= Popup event ======================== */

.popup_event {
  padding: 15px 11px 20px 11px;
}

.popup_event-flex {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5rem;
}

.popup_event-text {
  font-weight: 600;
  font-size: 20px;
  color: #1d1d1b;
  margin-left: 0.5rem;
}

.popup_event--text {
  font-weight: 600;
  font-size: 18px;
  color: #1d1d1b;
  margin-left: 0.5rem;
  margin-bottom: 1.5rem;
}

.popup_event__text {
  font-weight: 400;
  font-size: 15px;
  color: #1d1d1b;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
}

.popup_event__title {
  font-weight: 600;
  font-size: 15px;
  color: #1d1d1b;
}

.popup-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ================================= Alert Popup ================= */

.alert-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
}

.alert-popup-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.alert-popup-icon>img {
  margin: 2rem;
}

.alert-popup-title {
  font-weight: 600;
  font-size: 18px;
  color: #1d1d1b;
  text-align: center;
}

.alert-popup-body {
  width: 100%;
  /* min-height: 30px; */
  max-height: 200px;
  overflow-y: scroll;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #1d1d1b;
  margin: 1rem;
}

.alert-popup-body::-webkit-scrollbar {
  width: 10px;
  /* height: 0; */
}

.alert-popup-mr {
  margin: 10px 10px 20px 10px;
}

/* ========================= Chech Box Text ==================== */

.check-box-text {
  font-weight: 400;
  font-size: 14px;
  color: #88989d;
  margin-left: 10px;
}

/* ========================== Page & Button Loader =============== */

.page-loader {
  width: 50px;
  height: 50px;
  border: 7px solid #00aee1;
  border-bottom-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: calc(30% - 25px);
  left: calc(50% - 25px);
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.page-loader-small {
  height: 25px;
  width: 25px;
  border-width: 4px;
  top: 60%;
}

.page-loader-in-place {
  position: relative;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.white-btn-loader {
  width: 11px;
  height: 11px;
  border: 3px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.blue-btn-loader {
  width: 11px;
  height: 11px;
  border: 3px solid #00aee1;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.black-btn-loader {
  width: 14px;
  height: 14px;
  border: 2px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.red-btn-loader {
  width: 14px;
  height: 14px;
  border: 2px solid red;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.dropdown-blue-loader {
  min-width: 15px;
  max-width: 15px;
  min-height: 15px;
  max-height: 15px;
  margin-right: 1rem;
  border: 2px solid #008fc3;
  border-bottom-color: transparent;
  border-radius: 60%;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.lookup-loader {
  width: 14px;
  height: 14px;
  border: 3px solid #008fc3;
  border-bottom-color: transparent;
  border-radius: 60%;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* ============================== Signel ==================== */

/* .complete-page {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: green;
  position: absolute;
} */

/* .incomplete-page {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: orange;
  position: absolute;
}

.panding-page {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
} */

/* .API-panding-page {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: purple;
  position: absolute;
  left: 0px;
} */

/* ====================== datepicker ================= */

.datepicker-portal {
  width: calc(100% - 20px);
  height: 30px;
  background: #ffffff;
  border: 1px solid #d7e8ec;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Jost';
  color: #1d1d1b;
}

.datepicker-portal-error {
  width: calc(100% - 20px);
  height: 30px;
  background: #ffffff;
  border: 1px solid #ff7070;
  border-radius: 5px;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Jost';
  color: #1d1d1b;
}

/* .datepicker-month {
} */

/* ======================= Error Sub Title  ================  */

.text-input-error-title {
  margin-top: 5px;
  /* margin-left: 15px; */
  font-weight: 500;
  font-size: 14px;
  color: #ff7070;
}

/* ======================= Border Size ==================== */

.border-line-1px {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  background-color: #f5f5f5;
}

.border-line-1px_10m {
  margin: 10px 0px;
  height: 1px;
  width: 100%;
  background-color: #f5f5f5;
}

.border-line-1_5px {
  height: 1.5px;
  background-color: #d7e8ec;
}

.border-line {
  height: 0.5px;
  width: 100%;
  background-color: #d7e8ec;
  margin-top: 10px;
  margin-bottom: 10px;
}

.border-line-none-m {
  height: 0.5px;
  width: 100%;
  background-color: #d7e8ec;
}

.border__line_mr {
  height: 0.5px;
  /* width: 87%; */
  background-color: #d7e8ec;
  margin-top: 1rem;
  margin-left: 3rem;
  margin-bottom: 1rem;
}

.last__line_mr {
  padding: 8px 0px;
}

.border__line_mr_tb {
  height: 0.5px;
  width: 100%;
  background-color: #d7e8ec;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.uni_borderline_align {
  display: flex;
  justify-content: flex-end;
}

.border__line_mr_tb__ {
  height: 0.5px;
  width: 87%;
  background-color: #d7e8ec;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.border__line_mr_tb_c {
  height: 0.5px;
  width: 100%;
  background-color: #d7e8ec;
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
}

.border__line_none {
  height: 0.5px;
  width: 100%;
  background-color: #d7e8ec;
}

.border__line_gt {
  height: 1px;
  width: 100%;
  background-color: #ededed;
}

.border__dashed {
  border: 1px dashed #d6d6d6;
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
}

.border__line_px {
  height: 1px;
  width: 100%;
  background-color: #eeeeee;
}

.border__line_px_ee_m {
  margin-top: 1.3rem;
  margin-bottom: 1.2rem;
  height: 1px;
  width: 100%;
  background-color: #eeeeee;
}

.border__line_px_f5 {
  height: 1px;
  width: 100%;
  background-color: #f5f5f5;
}

.border__line_px_e1 {
  height: 1px;
  width: 100%;
  background-color: #e1ecef;
}

.border_line_e7 {
  height: 0.5px;
  background-color: #e7e7e7;
  margin: 10px - 10px;
}

.border__line_mt_Ship_ {
  height: 0.5px;
  width: 100%;
  background-color: #b1b1b1;
  margin: 1.5rem 0rem 1.5rem 0rem;
}

.border__line_mt_Ship__ {
  height: 0.5px;
  width: 100%;
  background-color: #b1b1b1;
  margin: 1.5rem 0rem 0rem 0rem;
}

/* ====================== Using Same Components ============= */

.basket-wrapper {
  position: relative;
  height: 50px;
  margin-bottom: 40px;
}

.quote-wrapper {
  position: relative;
  height: 40px;
  margin-bottom: 40px;
}

.error404 {
  margin-top: 4rem;
  height: 100vh;
}

.delBtnBG {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #fbe1e1;
  padding: 10px;
  border-radius: 50px;
}

.delBtnBG_ {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #fbe1e1;
  padding: 10px;
  border-radius: 50px;
  height: 20px;
  width: 20px;
}

.delBtnBG___ {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #fbe1e1;
  padding: 10px;
  border-radius: 50px;
  height: 10px;
  width: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.mini-inputtext {
  width: 170px;
}

/* ====================== ORange - Check Box ========================= */

.checkbox-org {
  display: block;
  position: relative;
  padding-left: 18px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-org input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-org {
  position: absolute;
  top: 1px;
  left: 6px;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-org:hover input~.checkmark-org {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-org input:checked~.checkmark-org {
  background-color: #f49600;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-org:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-org input:checked~.checkmark-org:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-org .checkmark-org:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border-radius: 1px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ====================== GRAY - Check Box ========================= */

.checkbox-gray {
  display: block;
  position: relative;
  padding-left: 18px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-gray input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-gray {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-gray:hover input~.checkmark-gray {
  background-color: #ccc;
}

/* When the checkbox is checked, add a background */
.checkbox-gray input:checked~.checkmark-gray {
  background-color: #d6d6d6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-gray:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-gray input:checked~.checkmark-gray:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-gray .checkmark-gray:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border-radius: 1px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ====================== DarK GRAY - Check Box ========================= */

.checkbox-darkgray {
  display: block;
  position: relative;
  padding-left: 18px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-darkgray input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-darkgray {
  position: absolute;
  top: 1px;
  left: 0px;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-darkgray:hover input~.checkmark-darkgray {
  background-color: #ccc;
}

/* When the checkbox is checked, add a background */
.checkbox-darkgray input:checked~.checkmark-darkgray {
  background-color: #a2b4b9;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-darkgray:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-darkgray input:checked~.checkmark-darkgray:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-darkgray .checkmark-darkgray:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border-radius: 1px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ======================== Bread Crumb  ================= */

.breadcrumb {
  font-weight: 400;
  font-size: 12px;
  color: black;
  text-align: center;
  text-decoration: none;
  margin-left: 0.1rem;
  margin-right: 0.2rem;
  margin-bottom: 0.1rem;
}

.breadcrumb-title {
  font-weight: 400;
  font-size: 12px;
  color: black;
  text-align: center;
  text-decoration: none;
  margin-left: 0.1rem;
  margin-right: 0.2rem;
  margin-bottom: 0.1rem;
  white-space: nowrap;
}

.bread_crumb {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: scroll;
}

.bread_crumb::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* ================ Section Margin & backGround ===================== */

.section-m-b {
  margin-left: 3%;
  margin-right: 3%;
  background-color: #fbfeff;
  scroll-behavior: smooth;
  margin-bottom: 3%;
}

.btn__mr {
  margin: 1rem 1.55rem;
}

/* ==================================== Sort By List ===================== */

.modual__ {
  margin-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 1rem;
  color: #000000;
}

.modual {
  display: flex;
  flex-direction: column;
}

.Sort-by-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
}

.modual:hover {
  background-color: #ebf5f9;
}

.Sort-by {
  color: #000000;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 10px;
}

.sort-by-title {
  width: 235px;
  text-align: center;
  margin-left: 25px;
}

.modual-box {
  border-radius: 10px;
  overflow: hidden;
  width: clamp(320px, 100%, 400px);
}

/* ================================= Drop Down Section ================== */

.drop-down-selection {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  color: black;
  outline: none;
  font-size: 14px;
}

#drop-down-select {
  /* width: 300px; */
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  color: black;
  outline: none;
  font-size: 14px;
  padding: 14.5px;
  background-color: #fff;
}

#dropdown-text {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ff7070;
  margin-left: 0px;
}

/* ================================= Top Scroll =====================  */
.scroll_view {
  border: 1px solid #e5f4f9;
  background-color: #e5f4f9c5;
  width: 40px;
  position: fixed;
  left: calc(50% + 95px);
  bottom: 50px;
  z-index: 1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 7px 25px;
  margin-right: 20px;
}

.scroll_view_ {
  border: 1px solid #e5f4f9;
  background-color: #e5f4f9c5;
  width: 40px;
  position: fixed;
  left: calc(50% + 95px);
  bottom: 90px;
  z-index: 1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 7px 25px;
  margin-right: 20px;
}

.btn-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 420px) {
  .scroll_view {
    background-color: #e5f4f9c5;
    width: 40px;
    position: fixed;
    left: calc(50% + 90px);
    bottom: 50px;
    z-index: 1;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 7px 25px;
  }
}

@media screen and (max-width: 357px) {
  .scroll_view {
    background-color: #e5f4f9c5;
    width: 40px;
    position: fixed;
    left: calc(50% + 65px);
    bottom: 50px;
    z-index: 1;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 7px 25px;
  }
}

/* ================================== Padding ======================= */

.padding20 {
  padding: 0px 20px;
}

/* ============================ Text Area ======================== */

#w3review {
  width: 100%;
  height: 100px;
  padding: 10px 12px;
  box-sizing: border-box;
  border: 1px solid #d7e8ec;
  border-radius: 5px;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  resize: none;
}

#w3review::placeholder {
  color: #979797;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

#w3review:focus {
  outline: none;
  border: 1px solid #d7e8ec;
  color: black;
  font-weight: 400;
}

/* ============================================================ */

.display_column {
  display: flex;
  flex-direction: column;
}

.display_column_pd {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.display_flex {
  display: flex;
}

/* ======================== LookUp Popup ================== */

.lookup_list_card {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin: 10px 10px;
}

.lookup_list_scroll {
  height: 350px;
  overflow-y: scroll;
}

.additem_list_scroll__ {
  height: 530px;
  overflow-y: scroll;
}

.lookup_list_scroll_ {
  height: 420px;
  overflow-y: scroll;
}

.lookup_list_scroll::-webkit-scrollbar {
  width: 0px;
}

.lookup_list_scroll_::-webkit-scrollbar {
  width: 0px;
}

/* .lookup_list_scroll::-webkit-scrollbar-thumb {
  background: #fafafa;
  border-radius: 10px;
} */

/* ================================= Extra ============================= */

.quota_mb_mt {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.edit-quote-mr-p {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 0px 10px;
}

.edit-quote-dwm {
  display: flex;
  width: 180px;
  margin-right: 10px;
}

.edit-quote-margin {
  margin: 0px 6px;
}

.edit-quote-wmr {
  width: 60px;
  margin-right: 10px;
}

.project-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loader_relative {
  position: relative;
  height: 100px;
}

.currquote_width {
  width: 150px;
}

.quote_dis_fx {
  display: flex;
  align-items: center;
}

.quote_dis_fx_mr_ {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.quota_di_fx_mrt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
  margin-top: 10px;
}

.confirm_dis_fx_mb {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.signin_dis_fx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signin_dis_fx_ev {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.quotes_dis_fx_mtb {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  padding-bottom: 10px;
}

.quotes_dis_fx {
  display: flex;
  justify-content: space-evenly;
}

.quote_text_upper {
  color: #a2b4b9;
  padding: 0px 20px;
  text-transform: uppercase;
}

.order_dis_fx_pb {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.quote_text_upper_pa {
  color: #a2b4b9;
  margin: 0;
  padding: 20px 25px 0px 25px;
  text-transform: uppercase;
}

.quote_dis_fx_mr {
  display: flex;
  align-items: center;
  margin-right: -1rem;
}

.order_dis_fx_mb_5px {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.upper_color_A2 {
  color: #a2b4b9;
  text-transform: uppercase;
}

.edit_ship_dis_sb_m {
  margin: 15px 10px 5px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit_ship_dis_co_p {
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
}

/* ================================= Extra css which added in inline ============================= */

.general-setting-email-cbox {
  margin: 10px -5px;
}

.edit-add-book-section {
  background-color: #fbfeff;
  padding-bottom: 2rem;
}

.edit-add-book-main-div {
  margin: 1rem 1.6rem;
}

.edit-add-book-child-div {
  margin-bottom: 1rem;
}

.edit-add-book-add-btn {
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
}

.change-stock-location-dropdown {
  margin: 20px 15px;
}

.change-pass-margin {
  margin: 5px 0px 20px 0px;
}

.addressbook-heading-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shipment-details-delete-model {
  width: 350px;
  margin: 20px 10px;
}

.quote-details-org-details {
  padding: 10px 20px 0px 20px;
}

.quote-details-org-details-view-more {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  padding-bottom: 10px;
}

.quote-details-org-details-view-more-btn {
  font-weight: 500;
}

.quote-details-org-details-view-less-btn {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.quote-details-other-details {
  color: #a2b4b9;
  padding: 0px 20px;
  text-transform: uppercase;
}

.quote-details-org-details-view-arrow {
  transition: all 0.2s linear;
}

.quote-details-del-details {
  padding: 0px 20px 0px 20px;
}

.quote-details-del-details-desc {
  padding: 0px 20px 10px 20px;
}

.quote-details-quote-details {
  padding-bottom: 10px;
}

.quote-details-add-item-btn {
  position: relative;
}

.quick-add-del-ml {
  margin-left: 0rem;
}

.quick-add-ml {
  margin-left: 2rem;
}

.quick-add-add-item__ {
  user-select: none;
}

.quick-add-mt {
  margin-top: 10px;
}

.online-payment {
  padding: 0px 20px;
  margin-top: 1rem;
}

.edit-shipment-other-item {
  display: flex;
  align-items: center;
  margin-left: -1rem;
}

.edit-shipment-other-item___ {
  display: flex;
  align-items: center;
  margin-right: -2rem;
}

.edit-shipment-other-item-ml {
  margin-left: 10px;
}

.edit-shipment-other-item-mr {
  margin-right: 10px;
}

.edit-shipment-other-item-mb {
  margin-bottom: 5px;
}

.edit-shipment-other-item-price {
  width: 100px;
  margin-right: 10px;
}

.edit-shipment-other-desc {
  width: 250px;
}

.edit-shipment-other-quantity {
  margin-right: 5px;
}

.edit-quote-bc-pb {
  background-color: #fafafa;
  padding-bottom: 2rem;
}

.edit-quote-part1-ml-mr {
  margin-left: 10px;
  margin-right: 10px;
}

.edit-quote-quotedetails {
  height: 1px;
  width: 100%;
  background-color: #d7e8ec;
  margin-top: 15px;
  margin-bottom: 10px;
}

.create-shipment-quote-details {
  color: #a2b4b9;
  padding: 0px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.create-shipment-item-price {
  padding: 5px 20px;
}

.padding10 {
  padding: 0px 10px;
}

.confirm-edit-odr-padding {
  padding: 0px 20px 20px 20px;
}

.con-creat-shipm-mr {
  margin-right: 7px;
}

.con-creat-shipm-ml {
  margin-left: 1.5rem;
}

.con-creat-shipm-m-pt {
  margin: 0;
  padding-top: 6px;
}

.add-item-pdding {
  padding: 0px 0px 10px 33px;
}

.add-item-height {
  height: 70dvh;
}

.add-item-model {
  margin: 10px 0px 0px 10px;
}

.add-item-padding-10 {
  padding: 10px 10px 10px 10px;
}

.storage-system-mb {
  margin-bottom: 0.2rem;
}

.product-grid-mt-pos {
  margin-top: 100%;
  position: relative;
}

.prod-category-pt {
  padding-top: 1rem;
}

.edit-order-mb-df {
  display: flex;
  margin-bottom: 1rem;
}

.recent-invoice-mr {
  margin-right: 0.6rem;
}

.documantation-mt-bc {
  background-color: #fbfeff;
  margin-top: 4px;
}

.welcome-margin {
  margin: 5px 0px;
}

.welcome-padding {
  padding: 6px 16px;
}

.sign-in-ml {
  margin-left: 30px;
}

.sign-in-padding {
  padding: 0px 1rem;
}

.reg-margin {
  margin: 5rem 1rem 1.5rem 1rem;
}

.reg-padding {
  padding: 2.5rem 1rem;
}

.login-height {
  position: relative;
  height: 70vh;
}

.home-back-color {
  background: #f8fcff;
}

.forgot-pass-mb-mt {
  margin-bottom: 10px;
  margin-top: 2.5rem;
}

.all-menu-margin {
  margin: 10px 10px 10px -12px;
}

.all-menu-margin-new {
  margin: 10px 10px 10px 25px;
}

.text-input-mb {
  margin-bottom: -5px;
}

.text-input-mr {
  margin-right: 1rem;
}

.text-area-resize {
  resize: none;
}

.check-module-ml {
  margin-left: -1.5rem;
}

.alt-popup-h-w {
  height: 66;
  width: 66;
}

.alt-popup-text-transform {
  text-transform: capitalize;
}

.lookup-back-height {
  background: #fafafa;
  height: 500px;
}

.lookup-margin {
  margin: 10px 0px 0px 0px;
}

.lookup-height {
  height: 500px;
}

.margin-right-8 {
  margin-right: 0.8rem;
}

.color-black {
  color: black;
}

.margin-right-6 {
  margin-right: 6px;
}

.product-details {
  text-align: right;
  width: 200px;
}

.product-details-padding {
  padding: 10px 0px 8px 0px;
}

.up-down-scroll {
  transform: rotate(180deg);
}

.quick-add-margin {
  margin: 10px 0px;
}

.quick-add-margin-model {
  margin: 10px 5px;
}

.quick-add-margin-model_ {
  margin: 10px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.project-issue-transform {
  transition: all 0.2s linear;
  height: 20px;
}

.chevron-aerrow-transform {
  transition: all 0.2s linear;
  height: 23px;
}

.dropdown-mr-bc {
  background-color: #fff !important;
  margin-right: 10px !important;
}

.content-mp {
  margin: 20px;
}

.rotate45 {
  transform: rotateZ(45deg);
}

.logo-height {
  height: 45px;
  mix-blend-mode: multiply;
}

.order-mp__ {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 7px 0px;
  background-color: #f7f7f7;
  border-radius: 50px;
}

.order-rs-mp__ {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px 0px 7px 0px;
  background-color: #f7f7f7;
  border-radius: 50px;
}


.edit_item_title {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #1D1D1B;
}

.edit_item_description {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #1D1D1B;
}

.Edit_QuoteItemDiv {
  border-bottom: 2px solid #D7E8EC;
}

._col_orange {
  color: #E56606;
}

._underline {
  text-decoration: underline;
}

.document__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;

}

.text-decoreation-none {
  text-decoration: none !important;
}

.shipment_currentorder_truck {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clr_blue {
  color: #008FC3;
}

.date-Picker-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 0 10px; */
}

.customDivForNewReturn1 {
  margin: 0 9px 0 8px;
  width: 100%;
}

.customDivForNewReturn2 {
  width: 100%;
  margin: 0 10px 0 7px;


}

.radio_custom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -2px 10px;
}


.radio_elements {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  font-size: 13PX;
  margin: 5px 0;
}

.radio_elements_ {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  font-size: 13PX;
  margin: 5px 5px;
}

.customdatepicker-title {
  height: 50px;
  width: 100%;
  left: -0.35px;
  position: absolute;
  top: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px 5px 0px 0px;
  font-size: 15px;
  color: #004761;
  text-decoration: underline;
  text-decoration-color: rgba(181, 180, 203, 0.99);
}

.radio_elements div {
  width: 50%;
}

.radio_elements div input {
  size: 50px;
}

.radio_elements div:nth-child(2) {
  padding-left: 28px;
}

.radio_elements div:nth-child(1) {
  padding-left: 5px;
}

.new_return_search {
  height: 50px;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 0 15px;
  width: 71%;
}

.new_return_search:focus {
  outline: none;
}

.go_button {
  background-color: #E2F2FF;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  color: #00AEE1;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.go_button:hover {
  background-color: #aad8fb;
  cursor: pointer;
}

.new_return_search_parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin: 14px 0 0 0;
}

.returnable_items {
  min-height: 50px;
  border-bottom: 0.5px solid rgb(246, 239, 239);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

.returnable_items span {
  margin: auto 20px;
  word-wrap: break-word;
  white-space: break-spaces;

}

.returnable_items:hover {
  background-color: #E3F3F9;
  cursor: pointer;
}

.partNoAndDescriptionTitle {
  padding: 12px 0 8px 5px;
  font-weight: 500;
}

.partNoAndDescriptionTitle_ {
  padding: 12px 0 8px 5px;
  font-weight: 500;
}

.item_list_div {
  overflow: scroll;
  height: 300px;
  /* margin-left: 2px; */
  margin: 0 15px;
}

.loader_item_list_div {
  position: relative;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8.5px;
}

.item_list_div::-webkit-scrollbar {
  display: none;
}

.new_return_question_list {
  margin: 0 15px;
}

.add-item-height_new_return {
  max-height: 70dvh;
  overflow: scroll;
  margin: 0 6px;
}

.add-item-height_new_return::-webkit-scrollbar {
  display: none;
}

.newReturn_popUp_button {
  margin: 15px;
}

/* ============================================= radio custom =========================== */

.radio-input {
  margin: 0;
  visibility: hidden;
}

.radio-input:checked+span {
  border: 1px solid #008FC3;
}

.radio-input:checked+span:after {
  opacity: 1;
}

.custom-radio {
  left: -8px;
  top: 6px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #D6D6D6;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

/* for inner filled circle */
.custom-radio::after {
  content: '';
  width: 14px;
  height: 14px;
  background: #008FC3;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.NotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.listing_parent_div_class {
  margin: 0px 0 0 -15px;
}