/* ============================= Product List Card =================== */

.product-list-card {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgba(218, 218, 218, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
  cursor: pointer;
}
.product-list-no-found-img {
  display: flex;
  justify-content: center;
  aspect-ratio: 71/29;
  padding: 11px;
  min-width: 100%;
}
.product-list-card-flex-col {
  display: flex;
  flex-direction: column;
  padding: 11px;
}
.product-list-card-flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}
.product-list-card-productcode {
  font-weight: 600;
  font-size: 13px;
  color: #1d1d1b;
}
.product-list-card-product_price {
  font-weight: 700;
  font-size: 16px;
  color: #1d1d1b;
}
.product-list-card-product__price {
  font-weight: 400;
  font-size: 12px;
  color: #1d1d1b;
}
.product-list-card-description {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #1d1d1b;
}

.product-list-card-des__menf {
  width: 225px;
  font-weight: 400;
  font-size: 12px;
  color: #1d1d1b;
}
.product-list-card__flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}
.product-list-card__flex-fe {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2px;
  width: 100%;
}
.productlist-due-date{
  width: 100%;
}

.flexW70 {
  display: flex;
  flex-direction: column;
  max-width: 68%;
  min-width: 68%;
}

.product-list-card__flex-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 2px;
}
.product-list-card-due-date {
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.product_text {
  font-weight: 400;
  font-size: 12px;
  color: #1d1d1b;
}
/* =============================== */

.productlist-data10 {
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
}
