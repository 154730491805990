.selected-item {
  text-align: center;
}

.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;
}

.controls {
  display: inline-flex;
  justify-content: space-around;
  background: #dfeaee;
  border-radius: 65px;
  width: 100%;
  padding: 7px 11px 7px 11px;
  margin: auto;
  overflow: hidden;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.7rem;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 200px;
  cursor: pointer;
  height: 35px;
  background: black;
}

.segment {
  width: 100%;
  position: relative;
  text-align: center;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 400;
  font-size: 13px;
  transition: color 0.5s ease;
}

.segment.active label {
  color: #00aee1;
  font-weight: 500;
  font-size: 13px;
}

.controls::before {
  content: "";
  background: #ffffff;
  border: 0.5px solid #62c6e9;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 8%);
  border-radius: 65px;
  width: calc(var(--highlight-width) + 18px);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -10px;
  margin: 0;
}

.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}

/* ============================= segments BIG =========================== */

.controlsbig {
  display: inline-flex;
  justify-content: space-around;
  background: #dfeaee;
  border-radius: 65px;
  width: 100%;
  padding: 11px 11px 11px 11px;
  margin: auto;
  overflow: hidden;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.7rem;
}

.controlsbig input {
  opacity: 0;
  margin: 0;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 200px;
  cursor: pointer;
  height: 35px;
  background: black;
}

.segmentbig {
  width: 100%;
  position: relative;
  text-align: center;
}

.segmentbig label {
  cursor: pointer;
  display: block;
  font-weight: 400;
  font-size: 15px;
  transition: color 0.5s ease;
}

.segmentbig.active label {
  color: #00aee1;
  font-weight: 500;
  font-size: 15px;
}

.controlsbig::before {
  content: "";
  background: #ffffff;
  border: 0.5px solid #62c6e9;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 8%);
  border-radius: 65px;
  width: calc(var(--highlight-width) + 18px);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -10px;
  margin: 0;
}

.controlsbig.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}
