.primary-btn-small {
  width: 90px;
  height: 32px;
  background: linear-gradient(91.28deg, #00aee1 0%, #55d0ec 100%);
  box-shadow: 0px 0px 19px #ccf4fd;
  border-radius: 5px;
  border: 0;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.secondary-btn-small {
  width: 90px;
  height: 32px;
  background: linear-gradient(99.09deg, #f49600 0%, #ffae2d 100%);
  box-shadow: 0px 0px 14px #fff5e6;
  border-radius: 5px;
  border: 0;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.white-btn-small {
  width: 90px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #000000;
  border-radius: 5px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.gray-btn-small {
  width: 90px;
  height: 32px;
  background: #d6d6d6;
  border-radius: 5px;
  border: 0;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.btn-small {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.btn-small:active {
  transform: scale(0.98);
}
