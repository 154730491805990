/* ===== planned-shipments-card ===== */

.planned-shipments-card {
  color: black;
  height: 190px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding-left: 3%;
  padding-top: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
}
.planned-shipments__card {
  color: black;
  height: 140px;
  box-shadow: 0px 0px 16px rgba(231, 231, 231, 0.25);
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  text-decoration: none;
}
.orange {
  background-color: #fffbf7;
}
.blue {
  background-color: #f2fafd;
}
.green {
  background-color: #eefcf0;
}
.white {
  background-color: white;
}

.torange {
  color: #f89732;
}
.tblue {
  color: #008fc3;
}
.tgreen {
  color: #57aa43;
}
.twhite {
  color: black;
}
.tred {
  color: #ff7070;
}
.planned-shipments-card-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.planned-shipments-card-dates {
  display: flex;
  align-items: flex-end;
  align-items: center;
  min-width: 200px;
}
.planned-shipments-card-dates > span {
  margin-left: 0.4rem;
}
.planned-shipments-card-type {
  min-width: 120px;
  text-align: right;
}
.planned-shipments-card-type > span {
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  text-align: right;
}
.planned-shipments-card-dispatch {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.planned-shipments-card__date {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.planned-shipments-card-no > span {
  font-weight: 600;
  font-size: 15px;
}
.planned-shipments-card-description > span {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.planned-shipments-card-day > span {
  font-size: 14px;
  font-weight: 400;
  color: #747474;
}

.planned-shipments-card__location {
  display: flex;
  align-items: flex-end;
}
.planned-shipments-card__location-title {
  font-size: 14px;
  margin-left: 0.4rem;
  color: black;
}
.planned-shipments-card__location-name {
  font-size: 14px;
  font-weight: 550;
  margin-left: 5px;
  color: black;
}
.planned-shipments-card__location-price {
  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  text-transform: capitalize;
}
