.search-news-card {
  background-color: #ffffff;
  border-radius: 5px;
  height: 100%;
  box-shadow: 0px 0px 14px rgb(202 202 202 / 25%);
  border-radius: 5px;
  padding-left: 11px;
  padding-top: 11px;
  padding-right: 11px;
  padding-bottom: 11px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.3rem;
}
.search-news-card-title {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 15px;
}
.search-news-card-img > img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.searchnews-data1 {
  margin-left: 1rem;
}
.search-news-card-date {
  font-weight: 400;
  font-size: 13px;
  color: #b1b1b1;
}
.search-news-card-summary {
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* ================================= Search Product Card ================= */

.search-products-card {
  background-color: #ffffff;
  border-radius: 5px;
  height: 100%;
  box-shadow: 0px 0px 14px rgb(202 202 202 / 25%);
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 11px;
  padding-right: 11px;
  padding-bottom: 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.3rem;
}
.search-products-card-productcode {
  font-weight: 500;
  font-size: 13px;
  color: #f49600;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.search-products-card-manufacturer {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-weight: 500;
  font-size: 14px;
  color: #1d1d1b;
}
.search-products-card-description {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
}

/* ================================ Search Project Card ================= */

.search-project-card {
  background-color: #ffffff;
  border-radius: 5px;
  /* height: 225px; */
  box-shadow: 0px 0px 14px rgb(202 202 202 / 25%);
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 11px;
  padding-right: 11px;
  padding-bottom: 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.3rem;
}
.search-project-card-title {
  font-weight: 500;
  font-size: 13px;
  color: #f49600;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.search-project-card-sub-title {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-weight: 500;
  font-size: 14px;
  color: #1d1d1b;
}
.search-project-card-description {
  margin-top: 0.3rem;
  margin-bottom: 2rem;
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
}

.search-project-card-flex-row {
  display: flex;
  flex-direction: row;
}
.search-project-card-flex-col {
  display: flex;
  flex-direction: column;
}
.search-project-card__flex-col {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
}
.search-project-card__flex__col {
  display: flex;
  flex-direction: column;
  margin-left: 4rem;
}
.search-project-card__title {
  font-weight: 500;
  font-size: 13px;
  color: #1d1d1b;
}
.search-project-card__body {
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
}
