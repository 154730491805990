.documentation-card {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgba(218, 218, 218, 0.25);
  border-radius: 5px;
  padding-bottom: 11px;
  margin-bottom: 11px;
}

.documentation-card-f {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px;
}

.documentation-card-title {
  font-weight: 600;
  font-size: 15px;
  color: #1d1d1b;
}

.documentation-card-info {
  font-weight: 400;
  font-size: 13px;
  color: #1d1d1b;
  padding: 0px 11px;
}

.documentation-card-link {
  font-weight: 600;
  font-size: 14px;
  color: #00aee1;
}

.documentation-card-text {
  font-weight: 400;
  font-size: 14px;
  color: #1d1d1b;
  padding: 0px 11px;
}

.documentation-img {
  margin-bottom: 1.5%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer
}

.documentation-img__ {
  /* margin-bottom: 1.5%; */
  width: 50%;
  height: 50%;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.documentation-img____ {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;
  margin: 6px 0;
}


.documentation-img-wide {
  margin-bottom: 1.5%;
  aspect-ratio: 3/1;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.documentation-img-4_1 {
  margin-bottom: 1.5%;
  aspect-ratio: 4/1;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.documentation__img {
  /* margin-bottom: 10px; */
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.documentation-flex-rp {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.documentation-img-align {
  display: flex;
  justify-content: center;
}

.documentation-card-download {
  width: 100px;
  height: 26px;
  background: linear-gradient(91.28deg, #00aee1 0%, #55d0ec 100%);
  border-radius: 35px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.documentation-card-download-text {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
}

/* ============================= Microgeneration ==================== */

.background-box {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgba(218, 218, 218, 0.25);
  border-radius: 5px;
  padding: 11px;
  margin-bottom: 11px;
  word-break: break-word;
}

.background-box___ {
  height: 100%;
  width: 33.33%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgba(218, 218, 218, 0.25);
  border-radius: 5px;
  padding: 11px;
  margin-bottom: 11px;
  word-break: break-word;
}

.microgeneration-title {
  font-weight: 500;
  font-size: 20px;
  color: #008FC3;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 5px;
}

.microgeneration-body {
  font-weight: 400;
  font-size: 15px;
  color: #1d1d1b;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.microgeneration-body a {
  font-weight: 400;
  font-size: 15px;
  text-decoration-line: underline;
  color: #008fc3;
}

.background-box-downloadContent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.background-box-downloadContent>img {
  width: 50%;
  margin-bottom: 1rem;
}


/* ================ Industry Guides =================== */

.industryguides-img {
  width: 80px;
  height: 100px;
  object-fit: contain;
}

.industryguides-flex {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.industryguides-body {
  font-weight: 600;
  font-size: 14px;
  color: #1d1d1b;
}

.industryguides-text {
  font-weight: 400;
  font-size: 14px;
  color: #59666a;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.industryguides-text a {
  font-weight: 400;
  font-size: 15px;
  text-decoration-line: underline;
  color: #008fc3;
}

.industryguides-body a {
  font-weight: 400;
  font-size: 15px;
  text-decoration-line: underline;
  color: #008fc3;
}

.background-box a {
  font-weight: 400;
  font-size: 15px;
  text-decoration-line: underline;
  color: #008fc3;
}


.iframeTag {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.iframeDiv {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  border-radius: 10px;
  margin-bottom: 5px;
}

.media_download_block {
  display: flex;
  justify-content: center;
}

.doc_detail_main {
  margin-bottom: 10px;
  display: flex;
}

.media_content_block_link {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.media_content_block_link_color_blue {
  background-color: #62C4E7;
  color: white !important;
  border-radius: 5px;
  text-decoration: underline;
  margin-bottom: 5px;
}

.media_content_block_link_color_orange {
  background-color: #E56606;
  color: white !important;
  text-decoration: underline;
  border-radius: 5px;
  margin-bottom: 5px;
}

.margin_bottom___ {
  margin-bottom: 10px;
}