
.trae-card {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgba(218, 218, 218, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
  cursor: pointer;
}
.trae-no-found-img {
  display: flex;
  justify-content: center;
  aspect-ratio: 71/29;
  padding: 11px;
  min-width: 100%;
}
.trae-padding {
  padding: 5px 10px;
}
.trae-title {
  font-weight: 600;
  font-size: 13px;
  color: #1d1d1b;
  margin: 5px 0px;
}
.trae-info {
  font-weight: 400;
  font-size: 13px;
  color: #5f5c5c;
  margin: 5px 0px;
}
.trae-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
}
.trae-text {
  font-weight: 400;
  font-size: 12px;
  color: #1d1d1b;
}
.trae-location {
  padding: 5px 5px;
  background: #fff9f0;
  border-radius: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.trae-location > img {
  margin-right: 2px;
}
.trae-location-name {
  font-weight: 500;
  font-size: 14px;
  color: #f49600;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trae-location-number {
  display: inline-block;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 2px;
}
