/* ==== Profile === */

.profile {
  margin-top: 2rem;
}

.profile-pic {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.profile-name {
  padding-top: 1rem;
  font-size: 18px;
  font-weight: 700;
}

.user-profile {
  width: 111px;
  height: 111px;
  border-radius: 50%;
  background: #62c6e9;
  border: 6px solid #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.user-profile-letter {
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  background: linear-gradient(180.02deg, #ffffff 10.17%, #f8fcff 99.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.locationDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Jost;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  margin-top: 4px;
}

.locationTitle {
  margin-left: 5px;
}

.locationName {
  font-weight: 500;
  display: inline-flex;
}