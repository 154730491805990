.filePickerComponent_normal {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #D7E8EC;
    border-radius: 5px;
    padding: 0 5px;
}

.diaplayFileName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
}

.filePickerComponent_error {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e13737;
    border-radius: 5px;
    padding: 0 5px;
}

.fileChooseButton {
    min-width: fit-content;
    height: 100%;
    border: none;
    background: transparent;

}

.textForFileChoose {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #008FC3;
    text-decoration: underline;
}