.selected-item {
  text-align: center;
}

.controls-containers {
  --highlight-widths: auto;
  --highlight-x-poss: 0;
}

.controlss {
  display: inline-flex;
  justify-content: space-around;
  background: #EBF5F9;
  border-radius: 5px;
  width: 6rem;
  padding: 5px;
  height: 1.9rem;
  margin: auto;
  overflow: hidden;
  position: relative;
  align-items: center;
}

.controlss input {
  opacity: 0;
  top: -6px;
  right: 0;
  bottom: 0;
  left: -5px;
  position: absolute;
  width: 50px;
  cursor: pointer;
  height: 38px;
  /* background-color: #00aee1; */
}

.segments {
  /* width: 100%; uncomment for each segment to have matching width */
  width: 100%;
  position: relative;
  text-align: center;
  /* z-index: 1; */
}

.segments input {
  cursor: pointer;
  display: block;
  font-weight: 700;
  /* padding: 5px; */
  transition: color 0.5s ease;
}

.segments.active label {
  color: #00aee1;
  align-self: center;
  padding: 0;
  margin: 0;
}
.segments label {
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.controlss::before {
  content: "";
  background: #ffffff;
  /* border: 0.5px solid #62c6e9; */
  box-shadow: 0px 0px 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  width: calc(var(--highlight-widths) + 3.5px);
  transform: translateX(var(--highlight-x-poss));
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 0px;
  margin: -2px;
  /* z-index: 0; */
}

/* Only allow transitions once component is ready */
.controlss.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}

/* var(--highlight-width) */

/* translateX(var(--highlight-x-pos)) */
