/* ---------------------------- News Details Card ---------------------------- */

.news-detail-card {
  height: auto;
  width: 100%;
  display: flex;
}

.news-detail-card1 {
  height: auto;
  width: 100%;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  color: #008FC3;
  font-weight: 600;
  font-size: 18px;
}

.news-detail-content12 {
  height: auto;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.news-detail-content8 {
  height: auto;
  width: 66%;
  margin-left: 10px;
  margin-right: 10px;

}

.news-detail-content4 {
  height: auto;
  width: 33%;
  margin-left: 10px;
  margin-right: 10px;
}

.news-detail-content6 {
  height: auto;
  width: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

.news-img {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  object-fit: cover;
}

.news-details-box {
  padding: 10px 5px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(214, 213, 213, 0.25);
  border-radius: 5px;
  margin: 0px 10px 10px 10px;
}