/* =========================== Input =========================== */
.user-input-normal {
  border: 0;
  width: 100%;
  font-size: 14px;
  padding: 10px 9px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: black;
  outline: none;
  background-color: #ffffff;
}


.user-input-normal__div {
  display: inline-block;
  border: 0;
  width: 100%;
  height: 40px;
  font-size: 14px;
  /* padding: 10px 9px; */
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: black;
  outline: none;
  background-color: #ffffff;
}

.user-input-normal::placeholder {
  color: #979797;
}


#button__ {
  background: transparent;
  border: none;
  width: 250px;
  height: 40px;
  border: 0;
  font-size: 14px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: black;
  outline: none;
  background-color: #ffffff;
  text-align: start;
}

#button__ span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

}

/* =========================== Normal TextInput =========================== */

.text-input-normal {
  background: #ffffff;
  border: 1px solid #d7e8ec;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* =========================== Success Text Input =========================== */

.text-input-success {
  background: #ffffff;
  border: 1px solid #57aa43;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Jost";
  font-style: normal;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
}

.text-input-success-title {
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #57aa43;
}

#button__ {
  background: transparent;
  border: none;
  width: 250px;
  height: 40px;
  border: 0;
  font-size: 14px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: black;
  outline: none;
  background-color: #ffffff;
  text-align: start;
}

#button__ span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

}

/* =========================== Error Text Input =========================== */

.text-input-error {
  background: #ffffff;
  border: 1px solid #ff7070;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Jost";
  font-style: normal;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}

.text-input-error_ {
  background: #ffffff;
  border: 1px solid #ff7070;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: "Jost";
  font-style: normal;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}

.input-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}