.mobile-view {
  background-color: #fbfeff;
  width: clamp(320px, 100%, 415px);
  margin: auto;
  position: relative;
  overflow: hidden;
  scroll-behavior: smooth;
}

#mobile-containt {
  margin-top: 6.3rem;
  padding-top: 1px;
  min-height: calc(100vh - 103px);
}
#navbar-fixed {
  min-width: clamp(320px, 100%, 415px);
  position: fixed;
  top: 0;
  z-index: 100;
  transition: top 0.3s;
}
.navbar-bg-img {
  background-image: url("../image/Hbg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-heder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  height: 54px;
}
.navbar__heder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 1.4rem 0rem 1.4rem;
  height: 54px;
}

.navbar__side-menu-icon {
  margin-left: 1.4rem;
  width: 70px;
}
.navbar__acc_noti-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.navbar__account-icon {
  margin-right: 0.8rem;
  margin-top: 4px;
}
.navbar__basket-icon {
  position: relative;
  margin-right: 1.3rem;
  display: flex;
  flex-direction: column-reverse;
  cursor: pointer;
}
.notification {
  background: #f49600;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin: 0;
  padding: 0px 5px;
  position: absolute;
  top: -7px;
  left: 12px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #1d1d1b;
}
.side-menu-bar {
  background-image: url("../../../../public/images/HistoryBG.svg");
  background-size: 300px auto;
  background-repeat: no-repeat;
  padding: 0px;
}
@media only screen and (max-width: 380px) {
  .side-menu-bar {
    padding: 5px;
  }
}
