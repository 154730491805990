.account-menu-box {
  background-color: #ffff;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 5px;
  box-shadow: 0px 0px 14px rgba(207, 207, 207, 0.25);
}
.account-menu-alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding-left: 1rem;
  text-decoration: none;
}
.account-menu-contant {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.account-menu-title {
  padding: 1rem;
  color: black;
}
.account-menu-Arr-icon > img {
  padding-right: 1.5rem;
}
.account-menu__icon {
  width: 23px;
  height: 23px;
}
