.Check_box_pm {
  padding: 11px 6px;
}

.Check_box_pm_ {
  display: flex;
  align-items: center;
  padding: 11px 6px;
  border-bottom: 1px solid #E7E7E7;
}

/* ======================================== Filter Check BOX ===================== */

.check_label_filter {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}

.check_label_filter input {
  display: none;
  visibility: hidden;
  cursor: pointer;
}

.check_mark_filter {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid #000000;
  border-radius: 3px;
}

.check_label_filter:hover input~.check_mark_filter {
  background-color: white;
  border: 1px solid #000000;
  border-radius: 3px;
}

.check_label_filter input:checked~.check_mark_filter {
  background-color: white;
  border: 1px solid #000000;
  border-radius: 3px;
}

.check_mark_filter:after {
  content: "";
  position: absolute;
  display: none;
}

.check_label_filter input:checked~.check_mark_filter:after {
  display: block;
}

.check_label_filter .check_mark_filter:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: 1px solid #00aee1;
  border-width: 0 2.5px 2.5px 0;
  transform: rotate(45deg);
  border-radius: 2px;
}

/* ======================================= Defult Chech BOX ===================== */

.check_label_defult {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.check_label_defult input {
  visibility: hidden;
  cursor: pointer;
}

.check_mark_defult {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background: #b3c8ce;
  border-radius: 5px;
}

.check_label_defult:hover input~.check_mark_defult {
  background: #a2b4b9;
  border-radius: 5px;
}

.check_label_defult input:checked~.check_mark_defult {
  background: #f49600;
  border-radius: 5px;
}

.check_mark_defult:after {
  content: "";
  position: absolute;
  display: none;
}

.check_label_defult input:checked~.check_mark_defult:after {
  display: block;
}

.check_label_defult .check_mark_defult:after {
  left: 7.5px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: 1.5px solid white;
  border-width: 0 2.5px 2.5px 0;
  transform: rotate(45deg);
  border-radius: 2px;
}


.check_label_defult_ {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  width: 400px;
  word-wrap: break-word;
  /* border-bottom: 1px solid #E7E7E7; */
  /* padding: 15px */
  display: flex;
  align-items: center;
}

.check_label_defult_ input {
  visibility: hidden;
  cursor: pointer;
}

.check_mark_defult_ {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background: #b3c8ce;
  border-radius: 5px;
}

.check_label_defult_:hover input~.check_mark_defult {
  background: #a2b4b9;
  border-radius: 5px;
}

.check_label_defult_ input:checked~.check_mark_defult {
  background: #00AEE1;
  border-radius: 5px;
}

.check_mark_defult_:after {
  content: "";
  position: absolute;
  display: none;
}

.check_label_defult_ input:checked~.check_mark_defult:after {
  display: block;
}

.check_label_defult_ .check_mark_defult:after {
  left: 7.5px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: 1.5px solid white;
  border-width: 0 2.5px 2.5px 0;
  transform: rotate(45deg);
  border-radius: 2px;
}

/* ========================================= Check Box TEXT ========================= */

.text_defult {
  font-weight: 400;
  font-size: 15px;
  color: #1d1d1b;
}

.newReturn_checkbox_label {
  font-weight: 400;
  font-size: 15px;
  color: #1d1d1b;
  word-wrap: break-word;
  width: 80%;
}

.check-box-text {
  font-weight: 400;
  font-size: 14px;
  color: #88989d;
}