.main-menu {
  font-size: 14px;
  width: 100%;
  position: absolute;
  left: calc(50% - 208px);
  top: 110px;
  /* background: rgba(0, 0, 0, 0); */
  background: #acd6e3;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  z-index: 1;
}
.main-menu1 {
  position: fixed;
}

.main-menu--open {
  /* background: rgba(0, 0, 0, 0.8); */
  background: #abcfdaaf;
  visibility: visible;
  animation-name: menu-fade-in;
  animation-duration: 0.5s;
  width: 415px;
  margin: -10px 1px;
}

.main-menu--closed {
  /* background: rgba(0, 0, 0, 0); */
  background: #acd6e3;
  visibility: hidden;
  animation-name: menu-fade-out;
  animation-duration: 0.5s;
  width: 415px;
  margin: -10px 1px;
}

.main-menu h2 {
  font-size: 14px;
  padding: 25px 20px 10px 20px;
  margin: 0;
}

.main-menu ul {
  list-style: none;
}

.main-menu li {
  display: block;
  border-bottom: 1px solid #ccc;
}

.main-menu li > span {
  display: block;
  padding: 15px 20px;
  cursor: pointer;
}

.main-menu li > span:hover {
  /* background-color: rgba(0, 0, 0, 0.1); */
  background: #abcfdaaf;
}

.main-menu li > a {
  display: block;
  padding: 15px 20px;
}

.main-menu a {
  color: #333;
}

.main-menu a:hover {
  text-decoration: none;
}

.main-menu__back-link {
  display: block;
  padding: 15px 20px 15px 35px;
  box-shadow: inset 0 7px 7px -7px #ddd;
}

.main-menu__back-link:hover {
  /* background-color: rgba(0, 0, 0, 0.1); */
  background: #abcfdaaf;
}

.main-menu__nav {
  width: 300px;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 100%;
  padding: 0;
  background: #fff;
  color: #333;
  height: 100%;
  overflow-y: auto;
  /* scrollbar-width: thin; */
}
@media only screen and (max-width: 380px) {
  .main-menu__nav {
    padding: 0px;
  }
}
.main-menu__nav1 {
  width: 115px;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: transparent;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
.main-menu__nav::-webkit-scrollbar {
  width: 0px;
}

.main-menu__nav--open {
  right: calc(100% - 300px);
  animation-name: menu-slide-in;
  animation-duration: 0.5s;
}
.main-menu__nav--closed {
  right: 100%;
  animation-name: menu-slide-out;
  animation-duration: 0.5s;
}

.main-menu--open {
  animation-name: menu-fade-in;
  animation-duration: 0.5s;
}

.main-menu--closed {
  animation-name: menu-fade-out;
  animation-duration: 0.5s;
}

.main-menu__nav--open {
  animation-name: menu-slide-in;
  animation-duration: 0.5s;
}

.main-menu__nav--closed {
  animation-name: menu-slide-out;
  animation-duration: 0.5s;
}
/* =================================== */
@keyframes animate-glow {
  from {
    opacity: 0;
    transform: scale(0.9, 0.9);
  }
  20% {
    transform: scale(0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes dotFlashingBlue {
  0% {
    background-color: #fff;
  }
  50%,
  100% {
    background-color: #00aee1;
  }
}

@keyframes dotFlashingOrange {
  0% {
    background-color: #fff;
  }
  50%,
  100% {
    background-color: #ef6606;
  }
}

@keyframes dotFlashingOrangeReverse {
  0% {
    background-color: #ef6606;
  }
  50%,
  100% {
    background-color: #fff;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes menu-slide-in {
  from {
    right: 100%;
  }
  to {
    right: calc(100% - 300px);
  }
}

@keyframes menu-slide-out {
  from {
    right: calc(100% - 300px);
  }
  to {
    right: 100%;
  }
}

@keyframes menu-slide-in-from-left {
  from {
    left: 100%;
  }
  to {
    left: calc(100% - 300px);
  }
}

@keyframes menu-slide-out-to-left {
  from {
    left: calc(100% - 300px);
  }
  to {
    left: 100%;
  }
}

@keyframes menu-fade-in {
  from {
    /* background: rgba(0, 0, 0, 0); */
    background: #abcfdaaf;
  }
  to {
    /* background: rgba(0, 0, 0, 0.8); */
    background: #abcfdaaf;
  }
}

@keyframes menu-fade-out {
  from {
    /* background: rgba(0, 0, 0, 0.8); */
    background: #abcfdaaf;
    visibility: visible;
  }
  to {
    /* background: rgba(0, 0, 0, 0); */
    background: #abcfdaaf;
    visibility: hidden;
  }
}
